
export default {
  name: "GalleryComponent",
  data() {
    return {
      //VARIABLES
      screenWidth: 0,
      screenHeight: 0,
      image: "",
      paddingContentGallery: "0% 0%",
    };
  },
  created() {

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    prev() {
      let num = this.imageSelected;

      if (this.imageSelected > 0) {
        num = num - 1;
        this.$store.commit("setGalleryImageSelected", num);
      }
    },
    next() {
      let num = this.imageSelected;
      if (this.imageSelected < this.allImages.length - 1) {
        num = num + 1;
        this.$store.commit("setGalleryImageSelected", num);
      }
    },
    handleResize: function () {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;

      if (window.innerWidth < 600) {
        this.paddingContentGallery = "35% 5%";
      } else if (window.innerWidth > 600 && window.innerWidth < 960) {
        this.paddingContentGallery = "20% 5%";
      } else if (window.innerWidth > 961 && window.innerWidth < 1264) {
        this.paddingContentGallery = "5% 10%";
      } else if (window.innerWidth > 1265 && window.innerWidth < 1904) {
        this.paddingContentGallery = "5% 15%";
      } else if (window.innerWidth > 1905) {
        this.paddingContentGallery = "5% 20%";
      }
    },
    closeDialog: function () {
      this.$store.commit("setGallery", {
        active: false,
        imageSelected: "",
        imagesArr: [],
      });
      this.$store.commit("setGalleryImageSelected", 0);
      this.image = "";
    },
  },
  computed: {
    active: function () {
      return this.$store.state.galleryActive;
    },
    imageSelected: function () {
      return this.$store.state.galleryImageSelected;
    },
    allImages: function () {
      return this.$store.state.gallerAllImages;
    },
  },
  watch: {
    active: function () {
      if (this.active) {
        this.image = this.allImages[this.imageSelected].oImages.lg;
      }
    },
    imageSelected: function () {
      if (this.active) {
        this.image = this.allImages[this.imageSelected].oImages.lg;
      }
    },
  },
};
